.button {
  width: max-content;
  min-width: 4rem;
  height: 2rem;
  padding: 0.125rem 1rem 0.125rem 1rem;

  color: var(--white);
  border: none;
  background-color: var(--ise-orange);

  font-size: 15px;
  font-family: "Roboto-Medium", "Roboto Medium", "Roboto", "sans-serif";
  font-weight: 700;

  transition-duration: 0.3s;

  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  outline: none;

  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 4px;
}

.button.button--outlined {
  color: var(--ise-orange);
  background: var(--ise-grad-white-konvex);
  border: 1px solid var(--ise-orange);
}

.button svg {
  fill: currentColor;
  margin-right: 0.625rem;
}

.button:hover:not([disabled]) {
  color: var(--main-button-background);
  background-color: var(--main-button-color);
}

button:hover {
  box-shadow: 0px 0px 0px 0.1875rem rgba( var(--ise-orange-100-rgb), 0.2);
}

.button:active {
  box-shadow: 0px 0px 0px 0.1875rem rgba( var(--ise-orange-100-rgb), 0.4);
}

.button.button--outlined:hover:not([disabled]) {
  color: var(--ise-orange);
}

.button:disabled {
  border-color: var(--main-button-color-disabled);
  /* color: var(--button-color-disabled); */
  background-color: var(--main-button-color-disabled);

  cursor: default;
  pointer-events: none;
}

.button::-moz-focus-inner {
  border: 0;
}
