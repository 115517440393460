
input[type=radio], input[type=file] {
  padding: 0px 10px;
  border: 1px solid var(--other-gray);
  border-radius: 2px;
  background-color: var(--main-background);
  font-size: 20px;
  color: var(--main-color);
}

input[type=text] {
  color: var(--color-black);
  line-height: var(--line-height);
  background: var(--color-white);
  box-shadow: var(--box-shadow-inset);
  font-size: var(--font-size-small);
  border: var(--border);
  border-radius: var(--border-radius-input);
  padding: .5rem;
  outline: none;
}

input[type="text"]:hover {
  box-shadow: 0px 0px 0px 3px rgba( var(--ise-orange-100-rgb), 0.2);
}

input:disabled {
  background-color: var(--disabled-gray);
  opacity: .6;
}

input:focus {
  border: var(--border-focus);
}