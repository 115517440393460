h1 {
  font-size: 48px;
  font-weight: 900;
  color: var(--main-color);
}
h2 {
  font-size: 24px;
  font-weight: bold;
  color: var(--main-color);
}
h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--main-color);
}
h6 {
  font-size: 12px;
  font-weight: normal;
  color: var(--gray);
  color: var(--main-color);
}

label,
p,
li {
  font-size: 16px;
  color: var(--main-color)
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 22px;
  }
  p,
  li {
    font-size: 15px;
  }
}
