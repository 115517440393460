.main {
  color: var(--main-color);
  margin: 0 auto;
  max-width: 1920px;
}

a {
  color: var(--main-color);
}

.mainContent {
  display: grid;
  grid-template-columns: minmax(20px, auto) minmax(0, 604px) minmax(20px, auto);
}

.mainContent h1 {
  font-size: 1.75rem;
  font-weight: 900;
  line-height: 2.125rem;
  letter-spacing: 0.4px;
  margin: 3rem 0 0 0;
}

.mainContent h2 {
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.875rem;
  letter-spacing: 0.4px;
  margin: 3rem 0 0 0;
}

.mainContent p {
  margin-top: 1.5rem;
  font-size: 0.938rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.2px;
}

.intro p,
.intro li{
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625rem;
  letter-spacing: 0.3px;
}

p {
  margin-bottom: 0
}

.intro p ~ p {
  margin-top: 1rem;
}

.intro ul {
  padding-left: 0;
  list-style-position: inside;
  margin-top: 0;
}

@media only screen and (min-width: 768px) {
  .mainContent {
    grid-template-columns: minmax(82px, auto) minmax(auto, 920px) minmax(82px, auto);
  }
}

@media only screen and (min-width: 768px) {
  .mainContent h1 {
    font-size: 2.25rem;
    line-height: 2.75rem;
    letter-spacing: 0.2px;
  }

  .mainContent h2 {
    font-size: 1.75rem;
    line-height: 2.215rem;
  }
}

@media only screen and (min-width: 992px) {
  .mainContent h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;
    letter-spacing: 0.4px;
  }
}

@media only screen and (min-width: 1200px) {
  .mainContent h1 {
    font-size: 3rem;
    line-height: 3.625rem;
    letter-spacing: inherit;
  }
}

@media only screen and (min-width: 1366px) {
  .mainContent h1 {
    margin: 4rem 0 0 0;
  }
}

.images_container{
  height: 88px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
}

.upload_box{
  border-style: groove;
}

.images{
  height: 80px;
  width: 56px;
}

.images_rotate{
  height: 56px;
  width: 80px;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.banner {
  overflow: hidden;
  margin-bottom: 20px;
}

.bannerImageContainer {
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  height: 500px;
  width: 100%;
}

.bannerBox {
  background-color: var(--lightgray);
  float: right;
  min-height: 220px;
  width: 300px;
  margin-top: -160px;
}

@media only screen and (min-width: 480px) {
  .banner {
    height: 500px;
  }

  .bannerImageContainer {
    width: 40%;
    height: 100%;
    float: left;
  }

  .bannerBox {
    width: 60%;
    margin: 0;
    height: 100%;
  }
}

@media only screen and (min-width: 480px) {
  .banner {
    height: 630px;
  }
}

@media only screen and (min-width: 1280px) {
  .banner {
    height: 800px;
  }
}

.content {
  grid-column-start: 2;
}

.hover {
  width: max-content;
  height: max-content;
  padding-right: 5px;
  border-radius: var(--border-radius-input)
}

.hover:hover {
  background: var(--ise-orange-20);
}