.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--overlay-background);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.overlay dialog {
    display: block;
    position: relative;
    width: calc(100% - 2.5rem);
    height: max-content;
    max-height: calc(100% - 2.5rem);
    padding: 0;
    background: var(--ise-white);
    border: 1px solid var(--ise-grey-40);;
    border-radius: .5rem;
    box-shadow: 0 .5rem 1rem rgba(var(--ise-black-100-rgb),.2);
}

.overlay .header {
    box-sizing: border-box;
    padding: .875rem 0.6875rem .875rem 2rem;
    height: 4.5rem;
    border-bottom: 1px solid var(--ise-grey-20);
    display: grid;
    grid-template-areas: "heading button";
    grid-template-columns: auto max-content;
}

.overlay .header__text {
    grid-area: heading;
    display: flex;
    align-items: center;
    margin: 0;
}

.overlay .header button {
    padding: 0;
    grid-area: button;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.overlay .header button:hover {
    box-shadow: none;
}

.overlay .content {
    border-bottom: 1px solid var(--ise-grey-20);
    padding: 1.5rem 2rem 2rem;
}

.overlay .footer {
    padding: .875rem 1.25rem .875rem 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.overlay .footer > * {
    margin-left: 1rem;
}

@media screen and (min-width: 600px) {
    .overlay dialog {
        width: 560px;
        display: grid;
        grid-template-areas: "header" "content" "footer";
        grid-template-rows: max-content auto 4.5rem;
        grid-template-columns: 100%;
    }

    .overlay .header {
        grid-area: header;
    }

    .overlay .content {
        grid-area: content;
    }

    .overlay .footer {
        grid-area: footer;
    }
}