:root {
    --input-padding: .5rem;
    --textarea-min-height: calc(var(--line-height) * var(--font-size) + var(--input-padding) * 2);
    --textarea-max-height: calc(var(--textarea-min-height) * 4);

    --border-size: 1px;

    --overlay-alternative: var(--color-white);

    --notification-color: var(--color-black);

    --base-size: 15px;
    --line-height: 1.25;
    --font-weight: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;
    --font-weight-xbold: 900;

    --font-family: Roboto, Helvetica, Arial, sans-serif;

    --font-size-xsmall: .7rem;
    --font-size-small: .85rem;
    --font-size: 1rem;
    --font-size-large: 1.1rem;
    --font-size-xlarge: 1.4rem;
    --font-size-xxlarge: 2rem;

    --ise-orange-rgb: 243, 153, 0;
    --ise-orange-100: #F39900;
    --ise-orange-90: #F4A319;
    --ise-orange-80: #f5ad33;
    --ise-orange-60: #F8C266;
    --ise-orange-40: #fad699;
    --ise-orange-20: #fdebcc;
    --ise-orange-10: #fef5e5;
    --ise-orange-100-rgb: 243, 153, 0;

    --ise-warm-grey-100: #3C4448;
    --ise-warm-grey-95: #464E51;
    --ise-warm-grey-20: #D8DADA;

    --ise-black-100: #000000;
    --ise-black-100-rgb: 0, 0, 0;
    --ise-grey-80: #333333;
    --ise-grey-70: #4d4d4d;
    --ise-grey-50: #7f7f7f;
    --ise-grey-40: #999999;
    --ise-grey-30: #b2b2b2;
    --ise-grey-20: #CCCCCC;
    --ise-grey-25: #BFBFBF;
    --ise-grey-03: #F7F7F7;
    --ise-grey-05: #f2f2f2;
    --ise-grey-08: #EBEBEB;

    --ise-white: #ffffff;
    --ise-white-rgb: 255, 255, 255;

    --ise-red-rgb: 255, 17, 68;
    --ise-red-100: #ff1144;
    --ise-red-05: #FFF3F5;

    --ise-yello-rgb: 255, 204, 0;
    --ise-yellow-100: #ffcc00;
    --ise-yellow-05: #FFFCF2;

    --ise-green-rgb: 0, 204, 51;
    --ise-green-100: #00cc33;
    --ise-green-05: #F2FCF5;

    --ise-blue-100: #0055ff;

    --ise-purple-100: #6e28ff;
    --ise-purple-40: #c5a9ff;

    --ise-hr-blue-100: #e2f1f1;

    --ise-grad-white-konvex: linear-gradient(var(--ise-white) 50%, var(--ise-grey-03) 100%);
    --ise-grad-white-konkav: linear-gradient(var(--ise-grey-03) 0%, var(--ise-white) 50%);
    --ise-grad-light-grey-konvex: linear-gradient(var(--ise-grey-03) 50%, var(--ise-grey-08) 100%);
    --ise-grad-light-grey-konkav: linear-gradient(var(--ise-grey-08) 50%, var(--ise-grey-03) 100%);
    --ise-grad-warm-grey-konkav: linear-gradient(var(--ise-warm-grey-95) 50%, var(--ise-warm-grey-100) 100%);
    --ise-grad-orange-konvex: linear-gradient(var(--ise-orange-90) 0%, var(--ise-orange-100) 50%);
    --ise-grad-orange-konkav: linear-gradient(var(--ise-orange-100) 50%, var(--ise-orange-90) 100%);

    --ise-produktiv-ease-out: cubic-bezier(0, 0, 0.38, 0.9);
    --ise-produktiv-ease-in: cubic-bezier(0.2, 0, 1, 0.9);
    --ise-produktiv-ease-in-out: cubic-bezier(0.2, 0.2, 0.38, 0.9);

    --ise-expressiv-ease-out: cubic-bezier(0, 0, 0.3, 1);
    --ise-expressiv-ease-in: cubic-bezier(0.4, 0.14, 1, 1);
    --ise-expressiv-ease-in-out: cubic-bezier(0.4, 0.14, 0.3, 1);

    --color-primary: var(--ise-orange-100);

    --color-black: #111;
    --color-white: #fff;
    --color-white-pure: #fff;
    --color-grey: #888888;
    --color-error: var(--ise-red-100);
    --color-error-light: var(--ise-red-05);
    --color-warning: var(--ise-yellow-100);
    --color-warning-light: var(--ise-yellow-05);
    --color-success: var(--ise-green-100);
    --color-success-light: var(--ise-green-05);
    --color-success-blur: var(--ise-green-05);
    --color-info: var(--ise-grey-50);
    --color-info-light: var(--ise-grey-05);
    --color-muted: var(--ise-grey-50);

    --color-border: var(--ise-grey-40);
    --color-border-highlight: var(--ise-orange-100);

    --highlight-width: 4px;

    --border-radius-input: 4px;
    --border: var(--border-size) solid var(--color-border);
    --border-focus: var(--border-size) solid var(--color-border-highlight);
    --box-shadow: 1px 1px 3px var(--color-border);
    --box-shadow-inset: inset 1px 1px 2px rgba(0,0,0, .25);
    --snackbar-shadow: 2px 2px 5px rgba(0,0,0,.4);

    --box-shadow-hover: 0 0 0 var(--highlight-width) rgba(var(--ise-orange-rgb), .2);
    --box-shadow-transition: box-shadow 100ms var(--ise-produktiv-ease-out);

    --switch-height: var(--font-size);

    --notification-color: var(--ise-grey-50);
    --tile-background: var(--ise-grey-05);
    --tile-background-light: var(--ise-white);

    --notification-header-weight: var(--font-weight-medium);
}