.footer {
  font-family: "Roboto";
  background-color: var(--footer-background);
  box-sizing: border-box;
}

.footer * {
  color: #fff;
  box-sizing: border-box;
  font-size: 15px;
}

.footer a {
  text-decoration: none;
  outline: none;
}

.footer nav a {
  font-weight: bold;
}

.footer nav a:hover {
  color: var(--ise-orange-100);
}

.footer ul {
  list-style-type: none;
  padding-left: 0;
}

.footer nav li {
  margin-bottom: 8px;
}

.footerIseLogo {
  max-width: 136px;
  height: auto;
  padding-left: 0;
}

.footerSection {
  background-color: var(--footer-background);;
  padding-top: 34px;
  padding-bottom: 24px;
  font-size: 15px;
  line-height: 20px;
  overflow: hidden;
}

.footerContainer {
  padding-right: 16px;
  padding-left: 16px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

/*@media (min-width: 540px){*/
/*  .footerContainer {*/
/*    max-width: 576px;*/
/*  }*/
/*}*/

/*@media (min-width: 768px){*/
/*  .footerContainer {*/
/*    max-width: 720px;*/
/*  }*/
/*}*/

/*@media (min-width: 992px){*/
/*  .footerContainer {*/
/*    max-width: 960px;*/
/*  }*/
/*}*/

/*@media (min-width: 1200px){*/
/*  .footerContainer {*/
/*    max-width: 1150px;*/
/*  }*/
/*}*/

.footerRow {
  margin-right: -16px;
  margin-left: -16px;
  display: flex;
  flex-wrap: wrap;
}

.footerColumn {
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 40px;
  flex: 0 0 100%;
  max-width: 100%;
}

.footerColumn p,
.footerColumn ul {
  margin-top: 0;
}

@media (min-width: 768px){
  .footerColumn {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media (min-width: 992px){
  .footerColumn {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 1200px){
  .footerColumn {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
}

.footerHeadLine {
  display: block;
  font-weight: 700;
  margin-bottom: 8px;
}

@media (min-width: 768px){
  .mdNone {
    display: none;
  }
}

.socialLinks {
  padding-top:4px;
  overflow: hidden;
}

.socialLinks ul {
  max-width: 112px;
  margin-bottom:1rem;
}

.socialLinks li {
  float: left;
  line-height: 0;
  margin: 0 12px 12px 0;
}

.socialLinks a {
  display: block;
  position: relative;
  width: 44px;
  height: 44px;
  z-index: 50;
  border-radius: 10px;
}

.socialLinks a:hover {
  background: var(--ise-grey-50);
}

.socialLinks img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 10;
  padding-left: 0;
}

