.form {
    display: flex;
    flex-direction: column;
}

.form label {
    margin: 5px 0;
}

.form label + input[type="text"] {
    margin-top: 0.625rem;
}

.form input + label {
    color: var(--ise-grey-50);
    margin-top: 2px;
    font-size: 12px;
}

.form p ~ p {
    margin-top: 0.5rem;
    margin-bottom: 0;
}

.downloadlogo {
    height: 17px;
    width: 17px;
    padding-right: 5px;
}