.container {
    box-sizing: border-box;
    padding: 5px 5px 5px 0;
}

.radio {
    display: block;
    position: relative;
    left: -3px;
    padding: 5px 5px 5px 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: max-content;
    height: 100%;
    margin: 0 !important;
    box-sizing: border-box;
}

.radio:hover {
    background: var(--ise-orange-20);
    border-radius: 4px;
}

.radio:focus {
    background: var(--ise-orange-40)
}

.radio div {
    width: max-content;
    height: 100%;
}

.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 5px;
    left: 3px;
    height: 18px;
    width: 18px;
    background-color: var(--ise-grad-white-konvex);
    border: 1px solid var(--ise-grey-40);
    border-radius: 50%;
}

/*.radio:hover input ~ .checkmark {*/
/*    background-color: #ccc;*/
/*}*/

.radio input:checked ~ .checkmark {
    background-color: var(--ise-orange-100);
    border-color: var(--ise-orange-40);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio input:checked ~ .checkmark:after {
    display: block;
}

.radio .checkmark:after {
    top: 6px;
    left: 6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
}