.languageSwitcher{
    background-color: var(--very-dark-grayish-yellow);
    margin-right: 50px;
  }

  .switch_icon{
    width: 22px !important;
    height: 22px !important;
    margin-right: 8px !important;
    padding: 0 !important;
  }

  .button{
    height: 32px;
    min-width: 80px;
    padding: 0 16px;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.3px;
    text-decoration: none;
    line-height: 32px;
    text-align: center;
    border-radius: 4px;
    background: transparent;
    color: #fff;
    border: 1px solid #DDDDDB;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
  }

  .button:hover {
    box-shadow: 0px 0px 0px 3px rgba( 191, 191, 191, 0.2 );
  }

  .button div {
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }