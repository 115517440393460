:root {
  --background: var(--white);

  --main-background: var(--white);
  --main-separator: var(--ise-background-gray);
  --main-color: var(--ise-warm-grey-100);
  --main-color-pale: var(--gray);
  --secondary-color: var(--boulder);
  --main-color-error: var(--outrageous-orange);
  --main-color-okay: var(--ise-okay);
  --main-button-color: var(--orange-peel);

  --main-button-background: var(--white);
  --main-control: var(--orange-peel);

  --overlay-content-background: var(--white);
  --overlay-background: hsla(0,0%,96.9%,0.9);

  --footer-background: var(--main-color);
  --footer-color: var(--white);
  --header-background: var(--main-color);
  --header-text-color: var(--white);
  --header-text-color-hover: var(--boulder);

  --dropdown-border: var(--silver);
  --dropdown-text: var(--gray);
  --dropdown-arrow: #6a6a6a;
  --dropdown-background: var(--white);

  --link-color: var(--gray);

  --disabled-gray: rgba(247,247,247,1);
  --disabled-gray-foreground: rgb(204,204,204);
  --button-border: rgb(204,204,204);

  --button-color: var(--white);
  --button-color-disabled: var(--disabled-gray-foreground);
  --main-button-color-disabled: var(--lightgray);

  --button-background-color: var(--gray);
  --button-background-color-hover: var(--mine-shaft);

  --burger-menu-background: var(--boulder);
  --burger-menu-item-color: var(--white);
  --burger-menu-item-color-hover: var(--main-color);
  --burger-menu-item-color-disabled: var(--menu-gray);

  --dashboard-state-ok: var(--ise-okay);
  --dashboard-state-not-ok: var(--outrageous-orange);
  --dashboard-state-loading: var(--gray);
  --dashboard-state-warning: var(--ise-orange);
}
