
.dropzone {
  display: none;
 }

@media( hover: hover){
  .dropzone:hover {
    background-color: var(--ise-orange-20);
    border: 1px solid var(--ise-orange);
  }
}


.centerBox{
  /* display: grid;
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  z-index: 0; */
 }

.infoText{
  font-size: 12px;
}

.downloadicon{
  justify-self: center;
  align-self: center;
}

.file{
  font-weight: 500;
  font-size: 15px;
  margin-top: .25rem;
  display: flex;
  align-items: flex-end;
}

.file p {
  margin: 0;
}

.vpnicon{
  height: 90%;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.vpnicon_selected{
  height: 18px;
  width: 13px;
  padding-right: 5px;
}


@media only screen and (min-width: 1366px) {
  .wrapper {
    position: relative;
    margin-top: 1.25rem;
    --dropzone-height: 200px;
  }

  .dropzone {
    height: var(--dropzone-height);
    padding: 20px;
    border-radius: 5px;
    border: 1px dashed var(--ise-grey-30);
    /* background-color: #fafafa; */
    color: #bdbdbd;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .file {
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  .fileOpenDialog{
    position: absolute;
    bottom: calc( var(--dropzone-height) * .2);
    left: 50%;
    transform: translateX(-50%);
  }

  .hideOnBig {
    display: none;
  }
}