:root {
  --white: #ffffff;
  --gallery: #ebebeb;
  --mercury: #e6e6e6;
  --alto: #d9d9d9;
  --silver: #c8c8c8;
  --bittersweet: #fc655d;
  --gray: #8c8c8c;
  --menu-gray: #acacac;
  --lightgray: #f2f2f2;
  --malachite: #00cc41;
  --malachite2: #00ba16;
  --ise-okay: #6edc6c;
  --ise-orange: #f39900;
  --dove-gray: #666666;
  --red: #ff0d00;
  --mine-shaft: #323232;
  --thunder: #231f20;
  --mine-shaft-dark: #1f1f1f;
  --black: #000000;
  --very-dark-grayish-yellow: #53534d;
  --ise-background-gray: #F3F3F3;
  --boulder: #7d7d7d;
  --orange-peel: #f39900;
  --outrageous-orange: #ff5e3a;
  --other-gray: #999999;
}
