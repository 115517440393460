.header {
  height: 4rem;
  background-color: var(--header-background);
  overflow: hidden;
}

.headerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 1920px;
  height: 100%;
  padding: 0 1.25rem;
  margin: 0 auto;
  overflow: hidden;
}



.header img{
  height:2rem;
  padding-top: 0.5125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
}

.headerContentLink {
  color: var(--header-text-color);
  text-decoration: none;
  margin-right: 32px;
  font-size: 18px;
  user-select: none;
}

.headerContentLink:last-child {
  margin-right: 0;
}

.headerContentLink:hover {
  color: var(--header-text-color-hover);
}

.headeritems {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
}

.headeritems > * {
  flex-shrink: 0;
}

.shrinkable {
  flex-shrink: 1;
  overflow: hidden;
  display: block;
}

#loginRegister {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .headerContent #burgerMenu {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .hide_on_small_screens {
    display: none;
  }
}

.headeritems nav {
  margin: 24px 0;
}

.seperator {
  padding-left: 24px;
  padding-right: 24px;
}

.logo {
  padding: 0 30px;
  display: flex; /* height of svg in a fix */
  flex-shrink: 0;
}

a.navigation:last-child {
  margin-right: 0;
  font-size: 18px;
}

@media only screen and (min-width: 992px) {
  .header {
    height: 5rem;
  }

  .headerContent {
    padding: 0 1.5rem
  }
}
